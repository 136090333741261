.bg-primary {
    --bs-bg-opacity: 1;
    font-family: Anton, sans-serif;
    font-size: 1.2rem;
    background-color: #ffd8d8!important;
  }
  
  .bg-secondary {
    font-family: Anton, sans-serif;
    font-size: 1.2rem;

    --bs-bg-opacity: 1;
    background-color: #ffffff78!important;
  }
  
  .row {
    --bs-gutter-x: 0.0rem;

}

p {
  margin-top: 0;
  margin-bottom: 1rem;
  font-family: 'Roboto';
}