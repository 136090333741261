@media  (max-width: 900px)
{
.css-i4bv87-MuiSvgIcon-root {
    font-size: 2.5rem;
  }
  
}




@media  (max-width: 900px) and (min-width: 600px)
{
  p.imgnav {
    width: 51vw;
  }
  
}


@media  (max-width: 600px) 
{
  p.imgnav {
    width: 60vw;
  }
  
}

@media  (min-width: 900px) 
{
  p.imgnav {
    width: 60vw;
    display: none;
  }
  
}