.project-img {

    width: 80vw;
    justify-items: center;
    border-radius: 10px;
}

.project-img-p{
    font-family: fantasy;
    text-align: center;
    margin-bottom: 0px;
}

p.project-img-p {
    padding: 45px;
    background-color: #ffd8d8;
}

 
  .project-container h1 {
    color: #2A9D8F;
  }

  li {
    font-family: system-ui;
}



.project-img-p2{
    font-family: fantasy;
    text-align: center;
}

p.project-img-p2 {
    padding: 45px;
    background-color: #fff;
}
.Project-but{
    max-width: 600px;
    margin: 20px auto;
    background-color: lightgray;
    font-family: Arial, sans-serif;
border-radius: 6px;

}

.Project-H2{
    cursor: pointer;
    color: rgb(42, 157, 143);
    padding-bottom: 0px;
}

.contact-mail{
    height: 400px;
    font-family: Arial, sans-serif;
    font-size: 1.5rem;
    color: #2A9D8F;
    cursor: pointer;
    align-content: center;
    background-image: url('../../public/image-blanc.png');
    background-size: cover;
    background-repeat: no-repeat;
}
.contact-mail-p{
    text-align: center;
}

ul {

    padding: 0;
}

ol, ul {
    padding-left: 0rem!important;
}